const data = [
  // {
  //   admissionNo: "9150",
  //   name: "VINAY KUMAR",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9150.pdf",
  // },
  // {
  //   admissionNo: "9157",
  //   name: "YASHRAJ",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9157.pdf",
  // },
  // {
  //   admissionNo: "9158",
  //   name: "MAYANK JHA",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9158.pdf",
  // },
  // {
  //   admissionNo: "9159",
  //   name: "MAYANK JHA",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9159.pdf",
  // },
  // {
  //   admissionNo: "9161",
  //   name: "MAYANK BENIWAL",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9161.pdf",
  // },
  // {
  //   admissionNo: "9184",
  //   name: "YASH",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9184.pdf",
  // },
  // {
  //   admissionNo: "9185",
  //   name: "CHETANSH YADAV",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9185.pdf",
  // },
  // {
  //   admissionNo: "9186",
  //   name: "ADITI",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9186.pdf",
  // },
  // {
  //   admissionNo: "9190",
  //   name: "YUGANSH",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9190.pdf",
  // },
  // {
  //   admissionNo: "9192",
  //   name: "GAURAV",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/9192.pdf",
  // },
  {
    admissionNo: "6285",
    name: "HARSH RAJ",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/6285.pdf",
  },
  {
    admissionNo: "6407",
    name: "SURAJ PAWAR",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/6407.pdf",
  },
  {
    admissionNo: "6877",
    name: "SHASHWAT JHA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/6877.pdf",
  },
  {
    admissionNo: "7130",
    name: "TANMAY KUMAR YADAV",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7130.pdf",
  },
  {
    admissionNo: "7364",
    name: "VANSHIKA LOHIYA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7364.pdf",
  },
  {
    admissionNo: "7697",
    name: "SASHMIT MISHRA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7697.pdf",
  },
  {
    admissionNo: "7715",
    name: "AAHAN CHANDRA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7715.pdf",
  },
  {
    admissionNo: "7737",
    name: "LASYA PRIYA YELLASIRI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7737.pdf",
  },
  {
    admissionNo: "8006",
    name: "AYUSH ARJUN KHARAT",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8006.pdf",
  },
  {
    admissionNo: "8292",
    name: "BHAVYA MISHRA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8292.pdf",
  },
  {
    admissionNo: "8400",
    name: "SIBANSHI MISHRA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8400.pdf",
  },
  {
    admissionNo: "8680",
    name: "ISHAANI ANCE PRIYA DARSHINI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8680.pdf",
  },
  {
    admissionNo: "8541",
    name: "AVNI BISWAJIT DALAI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8541.pdf",
  },
  {
    admissionNo: "8669",
    name: "KANIRA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8669.pdf",
  },
  {
    admissionNo: "8743",
    name: "KUMAR SHIVENDRA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8743.pdf",
  },
  {
    admissionNo: "8762",
    name: "KUMAR KAUSTAUBH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8762.pdf",
  },
  {
    admissionNo: "8775",
    name: "CHETANYA SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8775.pdf",
  },
  {
    admissionNo: "8866",
    name: "SMRITI S",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8866.pdf",
  },
  {
    admissionNo: "8867",
    name: "NIHIT S",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8867.pdf",
  },
  {
    admissionNo: "7666",
    name: "NAMIT SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7666.pdf",
  },

  {
    admissionNo: "8086",
    name: "VIDHAN PARIHAR",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8086.pdf",
  },
  {
    admissionNo: "8318",
    name: "ABHINAV RAJ",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8318.pdf",
  },
  {
    admissionNo: "8319",
    name: "AADI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8319.pdf",
  },
  {
    admissionNo: "8356",
    name: "DISHMEET KAUR BAINS",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8356.pdf",
  },
  {
    admissionNo: "8365",
    name: "RAJ KUMAR EDWARD",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8365.pdf",
  },

  {
    admissionNo: "8380",
    name: "ADWIK SHARMA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8380.pdf",
  },
  {
    admissionNo: "8475",
    name: "LAAVANYA JAIN",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8475.pdf",
  },
  {
    admissionNo: "5846",
    name: "K SNEHA RAO",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/5846.pdf",
  },
  {
    admissionNo: "6074",
    name: "MUDIT NAUGAIN",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/6074.pdf",
  },
  {
    admissionNo: "6372",
    name: "RONIT LOHIYA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/6372.pdf",
  },
  {
    admissionNo: "6501",
    name: "ADITYA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/6501.pdf",
  },
  {
    admissionNo: "7044",
    name: "MANAS",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7044.pdf",
  },
  {
    admissionNo: "7126",
    name: "VERONICA SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7126.pdf",
  },
  {
    admissionNo: "7488",
    name: "STUTI SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7488.pdf",
  },
  {
    admissionNo: "7530",
    name: "ABHINIBESH DUTTA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7530.pdf",
  },
  {
    admissionNo: "7541",
    name: "ADITYA MATHUR",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7541.pdf",
  },
  {
    admissionNo: "7623",
    name: "DHARA GUPTA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7623.pdf",
  },
  {
    admissionNo: "7648",
    name: "GAURI NANDANANA S",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7648.pdf",
  },
  {
    admissionNo: "7649",
    name: "MADHAV AJITH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7649.pdf",
  },
  {
    admissionNo: "7132",
    name: "VEDANT RAWAT",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7132.pdf",
  },
  {
    admissionNo: "7423",
    name: "AHAN MATHUR",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7423.pdf",
  },

  {
    admissionNo: "7640",
    name: "GAURINANDANA S",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/7640.pdf",
  },
  {
    admissionNo: "8115",
    name: "PRANJAL SHARMA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8115.pdf",
  },
  {
    admissionNo: "8285",
    name: "MANVIK SETHI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8285.pdf",
  },
  {
    admissionNo: "8304",
    name: "RUDRANSH DHAMI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8304.pdf",
  },
  {
    admissionNo: "8373",
    name: "VARDA SETHI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8373.pdf",
  },
  {
    admissionNo: "8495",
    name: "AARAV SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2023/8495.pdf",
  },

  {
    admissionNo: "8723",
    name: "VIRAJ SOOD",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8723.pdf",
  },
  {
    admissionNo: "7744",
    name: "SAMRIDH ATRI",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7744.pdf",
  },
  {
    admissionNo: "7764",
    name: "VARNIKA SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7764.pdf",
  },

  {
    admissionNo: "7807",
    name: "DARSHITA RAWAT",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7807.pdf",
  },
  {
    admissionNo: "7880",
    name: "SAMRAT SHARMA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7880.pdf",
  },
  {
    admissionNo: "7909",
    name: "HARDIK VERMA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7909.pdf",
  },
  {
    admissionNo: "7985",
    name: "PRATIK",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7985.pdf",
  },
  {
    admissionNo: "8079",
    name: "SAVYA TANDON",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8079.pdf",
  },
  {
    admissionNo: "8181",
    name: "SAMRIDHI SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8181.pdf",
  },
  {
    admissionNo: "8274",
    name: "SHIVANSH SAXENA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8274.pdf",
  },
  {
    admissionNo: "8492",
    name: "ANIKET VINAYAK",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8492.pdf",
  },
  {
    admissionNo: "8570",
    name: "SANSA KUMAR",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8570.pdf",
  },
  {
    admissionNo: "8592",
    name: "MIHIKA SHARMA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8592.pdf",
  },
  {
    admissionNo: "8667",
    name: "VRIDDHI GABA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/8667.pdf",
  },
  {
    admissionNo: "6086",
    name: "DAKSH SANSANWAL",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/6086.pdf",
  },
  {
    admissionNo: "6787",
    name: "MOZIJA SINGH TANWAR",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/6787.pdf",
  },
  {
    admissionNo: "7016",
    name: "SHREYA GUPTA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7016.pdf",
  },
  {
    admissionNo: "7017",
    name: "SHREYAS PANDEY",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7017.pdf",
  },
  {
    admissionNo: "7091",
    name: "AKSHITA CHAUHAN",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7091.pdf",
  },
  {
    admissionNo: "7105",
    name: "VEER KAUSHIK",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7105.pdf",
  },
  {
    admissionNo: "7539",
    name: "HIMANSHI SACHDEVA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7539.pdf",
  },
  {
    admissionNo: "7542",
    name: "BHAVISHYA ARYA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7542.pdf",
  },
  {
    admissionNo: "7591",
    name: "SHAURYA LOHIYA",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7591.pdf",
  },
  {
    admissionNo: "7609",
    name: "AADHYA KAUSHIK",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7609.pdf",
  },
  {
    admissionNo: "7710",
    name: "AARUSH SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2022/7710.pdf",
  },
  {
    name: "NANDINI CHATTERJEE",
    date: "05/08/2020",
    admissionNo: "4890",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4890.pdf",
  },
  {
    name: "KASHIKA M SARASWATHY",
    date: "05/08/2020",
    admissionNo: "4894",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4894.pdf",
  },
  {
    name: "HITAISHI CHITKARA",
    date: "13/8/2020",
    admissionNo: "4909",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4909.pdf",
  },
  {
    name: "HARSH CHOUDHARY",
    date: "13/8/2020",
    admissionNo: "4917",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4917.pdf",
  },
  {
    name: "PRATHAM SINGHAL",
    date: "09/08/2020",
    admissionNo: "4932",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4932.pdf",
  },
  {
    name: "ADHYAN TIWARI",
    date: "09/08/2020",
    admissionNo: "4934",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4934.pdf",
  },
  {
    name: "PRASHEN SINGH",
    date: "13/8/2020",
    admissionNo: "4941",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4941.pdf",
  },
  {
    name: "HIMANSHU NEGI",
    date: "13/8/2020",
    admissionNo: "4946",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/4946.pdf",
  },
  {
    name: "PREM SHRESHTH",
    date: "17/8/2020",
    admissionNo: "5134",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5134.pdf",
  },
  {
    name: "NIKHIL SINGH NEGI",
    date: "24/8/2020",
    admissionNo: "5341",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5341.pdf",
  },
  {
    name: "SOMIL BATRA",
    date: "31/8/2020",
    admissionNo: "5385",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5385.pdf",
  },
  {
    name: "DEV SHARMA",
    date: "31/8/2020",
    admissionNo: "5433",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5433.pdf",
  },
  {
    name: "ANMOL KIRTIWARDHAN",
    date: "16/7/2020",
    admissionNo: "5435",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5435.pdf",
  },
  {
    name: "YASH BABBAR",
    date: "17/8/2020",
    admissionNo: "5448",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5448.pdf",
  },
  {
    name: "PIYUSH SINGH",
    date: "31/8/2020",
    admissionNo: "5541",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5541.pdf",
  },
  {
    name: "RISHIT",
    date: "06/08/2020",
    admissionNo: "5702",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5702.pdf",
  },
  {
    name: "PRERNA SRIRAJSI",
    date: "16/7/2020",
    admissionNo: "5717",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5717.pdf",
  },
  {
    name: "S JAYANTH",
    date: "16/7/2020",
    admissionNo: "5753",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5753.pdf",
  },
  {
    name: "TANMAI KRISHAN",
    date: "10/08/2020",
    admissionNo: "5761",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5761.pdf",
  },
  {
    name: "ANSHUL MISHRA",
    date: "13/8/2020",
    admissionNo: "5961",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/5961.pdf",
  },
  {
    name: "PRATIBIMB SWAIN",
    date: "21/8/2020",
    admissionNo: "6058",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6058.pdf",
  },
  {
    name: "RUDRANSH SHARMA",
    date: "17/8/2020",
    admissionNo: "6138",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6138.pdf",
  },
  {
    name: "ZENITH ABBIGAIL",
    date: "31/3/2021",
    admissionNo: "6277",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6277.pdf",
  },
  {
    name: "BHOUMIK",
    date: "31/3/2021",
    admissionNo: "6278",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6278.pdf",
  },
  {
    name: "MEHAK ARORA",
    date: "02/07/2021",
    admissionNo: "6348",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6348.pdf",
  },
  {
    name: "NITYA KUMARI",
    date: "31/5/2020",
    admissionNo: "6350",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6350.pdf",
  },
  {
    name: "JEETAIN SEHRAWAT",
    date: "08/05/2020",
    admissionNo: "6584",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6584.pdf",
  },
  {
    name: "A S HARI AYODHI",
    date: "31/3/2020",
    admissionNo: "6613",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6613.pdf",
  },
  {
    name: "MOHIT SINGH",
    date: "13/8/2020",
    admissionNo: "6668",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6668.pdf",
  },
  {
    name: "PARTH SAMRAT",
    date: "31/3/2020",
    admissionNo: "6716",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6716.pdf",
  },
  {
    name: "DAKSH AHLUWALIA",
    date: "31/3/2020",
    admissionNo: "6760",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6760.pdf",
  },
  {
    name: "PARNIKA SINGH",
    date: "31/8/2020",
    admissionNo: "6790",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6790.pdf",
  },
  {
    name: "KARTIK YADAV",
    date: "07/09/2020",
    admissionNo: "6853",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6853.pdf",
  },
  {
    name: "PAVITRA PREM",
    date: "18/8/2020",
    admissionNo: "6866",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6866.pdf",
  },
  {
    name: "PRAGYA SANSANWAL",
    date: "29/5/2020",
    admissionNo: "6878",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6878.pdf",
  },
  {
    name: "SHRISHTI YADAV",
    date: "9/10/0200",
    admissionNo: "6889",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6889.pdf",
  },
  {
    name: "AISHWARYA BAUNTHIYAL",
    date: "31/3/2021",
    admissionNo: "6931",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6931.pdf",
  },
  {
    name: "AADITY KUMAR SINGH",
    date: "17/8/2020",
    admissionNo: "6934",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6934.pdf",
  },
  {
    name: "VANSHIKA JAMWAL",
    date: "13/8/2020",
    admissionNo: "6942",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6942.pdf",
  },
  {
    name: "A S DIVYASRI",
    date: "31/3/2020",
    admissionNo: "6975",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6975.pdf",
  },
  {
    name: "ANANYA SHARMA",
    date: "31/32021",
    admissionNo: "6985",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/6985.pdf",
  },
  {
    name: "ANKUSH SINGH SEHRAWAT",
    date: "13/4/2021",
    admissionNo: "7106",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7106.pdf",
  },
  {
    name: "YASHVI BHATIA",
    date: "30/6/2020",
    admissionNo: "7123",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7123.pdf",
  },
  {
    name: "PANNEM CHARVI",
    date: "31/3/2021",
    admissionNo: "7125",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7125.pdf",
  },
  {
    name: "R JAI SURYA",
    date: "31/3/2020",
    admissionNo: "7137",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7137.pdf",
  },
  {
    name: "SHREYAS UPADHYAY",
    date: "01/07/2021",
    admissionNo: "7153",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7153.pdf",
  },
  {
    name: "ANIKA JAIN",
    date: "31/3/2020",
    admissionNo: "7169",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7169.pdf",
  },
  {
    name: "ANISH PANDA",
    date: "31/3/2020",
    admissionNo: "7170",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7170.pdf",
  },
  {
    name: "RITOBROTO MUKHERJEE",
    date: "31/3/2021",
    admissionNo: "7188",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7188.pdf",
  },
  {
    name: "SAKSHAM DEOLI",
    date: "03/04/2021",
    admissionNo: "7192",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7192.pdf",
  },
  {
    name: "ABISMAR SAHOO",
    date: "31/3/2021",
    admissionNo: "7210",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7210.pdf",
  },
  {
    name: "BHANU PRIYA",
    date: "06/04/2021",
    admissionNo: "7260",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7260.pdf",
  },
  {
    name: "DIVYANSH SHARMA",
    date: "31/3/2020",
    admissionNo: "7262",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7262.pdf",
  },
  {
    name: "ANCIKA WORCHUI",
    date: "31/3/2021",
    admissionNo: "7290",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7290.pdf",
  },
  {
    name: "SWASTIKA RATHI",
    date: "31/3/2021",
    admissionNo: "7317",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7317.pdf",
  },
  {
    name: "VAIBHAV KUMAR GAUTAM",
    date: "03/04/2020",
    admissionNo: "7320",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7320.pdf",
  },
  {
    name: "SARVOCCH SINGH",
    date: "24/8/2020",
    admissionNo: "7344",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7344.pdf",
  },
  {
    name: "NITHYA PRIYA",
    date: "13/8/2020",
    admissionNo: "7346",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7346.pdf",
  },
  {
    name: "NISHANT SANSANWAL",
    date: "29/5/2020",
    admissionNo: "7379",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7379.pdf",
  },
  {
    name: "PRIYANSHI PANIGRAHI",
    date: "31/3/2020",
    admissionNo: "7394",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7394.pdf",
  },
  {
    name: "RIDHIMA PACHAURI",
    date: "12/01/2021",
    admissionNo: "7419",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7419.pdf",
  },
  {
    name: "ARNAV SINGH",
    date: "09/09/2020",
    admissionNo: "7444",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7444.pdf",
  },
  {
    name: "AAKRITI SHARMA",
    date: "31/3/2021",
    admissionNo: "7470",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7470.pdf",
  },
  {
    name: "PRAKHAR RAJPUT",
    date: "15/4/2020",
    admissionNo: "7549",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7549.pdf",
  },
  {
    name: "SATWIK TRIPATHI",
    date: "31/3/2020",
    admissionNo: "7551",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7551.pdf",
  },
  {
    name: "AYAAN KASINATH",
    date: "31/3/2021",
    admissionNo: "7571",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7571.pdf",
  },
  {
    name: "SOFIA RIAZ",
    date: "31/3/2021",
    admissionNo: "7583",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7583.pdf",
  },
  {
    name: "ADWITA MISHRA",
    date: "31/3/2021",
    admissionNo: "7650",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7650.pdf",
  },
  {
    name: "AADYA KUMARI",
    date: "31/5/2020",
    admissionNo: "7670",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7670.pdf",
  },
  {
    name: "AARADHYA GUPTA",
    date: "31/3/2021",
    admissionNo: "7675",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7675.pdf",
  },
  {
    name: "ARUNIMA MOZUMDAR",
    date: "31/3/2020",
    admissionNo: "7686",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7686.pdf",
  },
  {
    name: "JANHAVI DAWARE",
    date: "31/3/2021",
    admissionNo: "7845",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7845.pdf",
  },
  {
    name: "URVEE JENA",
    date: "31/3/2020",
    admissionNo: "7866",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7866.pdf",
  },
  {
    name: "AKSHAJ MANI TRIPATHI",
    date: "01/05/2021",
    admissionNo: "7883",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7883.pdf",
  },
  {
    name: "LUV",
    date: "31/3/2020",
    admissionNo: "7884",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7884.pdf",
  },
  {
    name: "VABYA VALINI",
    date: "31/8/2020",
    admissionNo: "7895",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7895.pdf",
  },
  {
    name: "AARAV SHARMA",
    date: "31/3/2021",
    admissionNo: "7931",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7931.pdf",
  },
  {
    name: "SHRUTI V",
    date: "13/8/2020",
    admissionNo: "7957",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7957.pdf",
  },
  {
    name: "ARUNEET SHASHWAT",
    date: "31/3/2020",
    admissionNo: "7964",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/7964.pdf",
  },
  {
    name: "ARJUN ADITYA KUMAR",
    date: "05/04/2020",
    admissionNo: "8015",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8015.pdf",
  },
  {
    name: "M PRANEETH ILESH",
    date: "06/04/2021",
    admissionNo: "8018",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8018.pdf",
  },
  {
    name: "AAFI ULLAH",
    date: "31/3/2020",
    admissionNo: "8033",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8033.pdf",
  },
  {
    name: "AHAAN KASINATH",
    date: "28/3/2021",
    admissionNo: "8038",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8038.pdf",
  },
  {
    name: "ADHWAN MISHRA",
    date: "28/3/2021",
    admissionNo: "8039",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8039.pdf",
  },
  {
    name: "AADVIK VERMA",
    date: "31/3/2020",
    admissionNo: "8040",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8040.pdf",
  },
  {
    name: "H L JASPER",
    date: "26/3/2021",
    admissionNo: "8053",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8053.pdf",
  },
  {
    name: "PANNEM SATHVIK",
    date: "28/3/2021",
    admissionNo: "8068",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8068.pdf",
  },
  {
    name: "SAMAR PRATAP SINGH",
    date: "31/3/2021",
    admissionNo: "8075",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8075.pdf",
  },
  {
    name: "SIDDH SANCHIT SADANGI",
    date: "28/3/2021",
    admissionNo: "8078",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8078.pdf",
  },
  {
    name: "DAANIA INSHA",
    date: "09/04/2020",
    admissionNo: "8100",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8100.pdf",
  },
  {
    name: "VIRAJ",
    date: "09/07/2020",
    admissionNo: "8143",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8143.pdf",
  },
  {
    name: "AROUSH",
    date: "31/3/2020",
    admissionNo: "8163",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8163.pdf",
  },
  {
    name: "AITREYA VARDHAN",
    date: "31/3/2021",
    admissionNo: "8187",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8187.pdf",
  },
  {
    name: "AMAN YADAV",
    date: "31/3/2020",
    admissionNo: "8190",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8190.pdf",
  },
  {
    name: "ANIKET MISHRA",
    date: "21/6/2021",
    admissionNo: "8194",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8194.pdf",
  },
  {
    name: "SAMARTH SRIVASTAVA",
    date: "22/6/2020",
    admissionNo: "8195",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8195.pdf",
  },
  {
    name: "TUSHAR SEVIN",
    date: "31/8/2020",
    admissionNo: "8225",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8225.pdf",
  },
  {
    name: "AVANI VIDYARTHI",
    date: "28/3/2021",
    admissionNo: "8260",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8260.pdf",
  },
  {
    name: "ADVAIT SUNIL DAWARE",
    date: "28/3/2021",
    admissionNo: "8289",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8289.pdf",
  },
  {
    name: "JASRAAJ SINGH",
    date: "18/8/2020",
    admissionNo: "8299",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8299.pdf",
  },
  {
    name: "VAISHNAVI PATHAK",
    date: "27/3/2021",
    admissionNo: "8315",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8315.pdf",
  },
  {
    name: "OJASWI KHANNA",
    date: "28/3/2021",
    admissionNo: "8317",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/tc/2021/8317.pdf",
  },
];

export default data;
